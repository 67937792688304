import React from "react";

//styles
import styles from "./Loader.module.scss";

//images
import loader from "../../../assets/icons/Loader.svg";

export const Loader = ({ width, height }) => {
  return (
    <div className={styles.loader}>
      <img style={{ width: width, height: height }} src={loader} alt="loader" />
    </div>
  );
};
