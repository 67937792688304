import React from "react";

//modules
import MainSlider from "../../modules/MainSlider/MainSlider";

const MainLazerSH2 = ({ stoks }) => {
  stoks.sort((a, b) => b.id - a.id);
  return <MainSlider array={stoks} />;
};
export default MainLazerSH2;
