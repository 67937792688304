import React from "react";

import "./FormModal.scss";

import modalBg from "../../../assets/images/general/form-modal-bg.png";
import modalBgSuccess from "../../../assets/images/general/form-modal-bg--success.png";
import closeBtn from "../../../assets/images/general/close-btn.svg";
import FormModalSign from "./FormModalSign";
import FormModalCallback from "./FormModalCallback";
import FormModalSuccess from "./FormModalSuccess";
import { connect } from "react-redux";
import { setModalState } from "../../../redux/actionCreators/modalActionCreators";

const FormModal = ({ modalState, setModalState }) => {
  const modalWrapClassName =
    modalState === "closed" ? "modal-wrap" : "modal-wrap active";

  const formRender = () => {
    if (modalState === "sign") {
      return <FormModalSign setModalState={setModalState} />;
    } else if (modalState === "callback") {
      return <FormModalCallback setModalState={setModalState} />;
    } else if (modalState === "success") {
      return <FormModalSuccess />;
    }
  };

  return (
    <div className={modalWrapClassName}>
      <div className="form-modal">
        <div className="form-modal__bg">
          <img
            src={modalState === "success" ? modalBgSuccess : modalBg}
            alt="modal background"
          />
        </div>

        <div
          className="form-modal__close-btn"
          onClick={() => setModalState("closed")}
        >
          <img src={closeBtn} alt="close button" />
        </div>

        <div className="form-modal__body">{formRender()}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    modalState: store.modalReducer.modalState,
  };
};

const mapDispatchToProps = {
  setModalState,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormModal);
