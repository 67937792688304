import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//style
import styles from "./Footer.module.scss";

//images
import logo from "../../assets/images/footer/Logo.png";
import instagram from "../../assets/images/footer/instagram.png";
import facebook from "../../assets/images/footer/facebook.png";

const Footer = () => {
  const { t } = useTranslation();

  const navigation = [
    {
      id: 1,
      link: "/services",
      name: t("header.navigation.services"),
    },
    {
      id: 2,
      link: "/prices",
      name: t("header.navigation.price"),
    },
    {
      id: 3,
      link: "/blog",
      name: t("header.navigation.blog"),
    },
  ];

  return (
    <footer className={styles.footer}>
      <div className={`${styles.inner} container`}>
        <div className={styles.logoSocials}>
          <Link to="/">
            <img src={logo} alt="" />
          </Link>

          <div className={styles.socials}>
            <div className={styles.icons}>
              <a
                href="https://www.facebook.com/NEW.DERM.CLINIC.Kyiv"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="facebook" />
              </a>
              <a
                href="https://www.instagram.com/new.derm.clinic/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="instagram" />
              </a>
            </div>
            <a
              href="tel:+380674421696"
              itemProp="telephone"
              target="_self"
              rel="noreferrer"
              className={styles.number}
            >
              +38 067 442 1696
            </a>
          </div>
        </div>
        <div className={styles.navWrapper}>
          <nav className={styles.nav}>
            <ul>
              {navigation.map(({ id, link, name }) => {
                return (
                  <li key={id}>
                    <Link to={link} className={styles.link}>
                      {name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
          <div className={styles.contacts}>
            <span className={styles.addresses}>{t("footer.street")}</span>
            <span>{t("footer.schedule")}:</span>
            <p> пн-пт. 10.00 - 19.00 сб. 11.00 - 18.00</p>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
