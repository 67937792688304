import React from "react";
import Carousel from "nuka-carousel";
import { useDispatch } from "react-redux";

//style
import styles from "./MainSlider.module.scss";
import "./MainSlider.scss";

//redux
import { setModalState } from "../../../redux/actionCreators/modalActionCreators";

//api
import { _image } from "../../../API/API_consts";

const MainSlider = ({ array }) => {
  const dispatch = useDispatch();
  const settings = {
    autoplay: true,
    wrapAround: true,
    speed: 1000,
    slidesToShow: 1,
    autoplayInterval: 4000,
  };
  return (
    <>
      <Carousel className={`${styles.slider} MainSlider`} {...settings}>
        {array?.map(({ id, photo }) => {
          return (
            <section className={styles.section} key={id}>
              <img
                src={`${_image}/${photo}`}
                alt=""
                className={styles.img}
                onClick={() => dispatch(setModalState("callback"))}
              />
            </section>
          );
        })}
      </Carousel>
    </>
  );
};
export default MainSlider;
