import React from "react";

//style
import "./Header.scss";

//components
import HeaderTop from "./HeaderTop/HeaderTop";
import HeaderNav from "./HeaderNav/HeaderNav";

const Header = () => {
  return (
    <header>
      <HeaderTop />
      <HeaderNav />
    </header>
  );
};
export default Header;
