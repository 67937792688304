import { getRequest } from "../../API/api";
import {
  clearLoadingAndError,
  setErrorMessage,
  setLoadingStatus,
} from "./appCreators";

import { SUCCESS_STOKS } from "../actionsConsts.js";

export const successStoks = (mas) => ({
  type: SUCCESS_STOKS,
  mas,
});

export const getStoks = (headers) => (dispatch) => {
  dispatch(setLoadingStatus());
  const success = (data) => {
    dispatch(successStoks(data.data));
    dispatch(clearLoadingAndError());
  };
  const setError = () => {
    dispatch(setErrorMessage());
  };
  getRequest({
    url: "/partners",
    success,
    setError,
    // headers,
  });
};
