import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//styles
import styles from "./SwitchLanguage.module.scss";

//redux
import { setLanguage } from "../../../redux/actionCreators/appCreators";

export const SwitchLanguage = () => {
  const dispatch = useDispatch();

  const { i18n } = useTranslation();

  const languageData = useSelector((state) => state.header.language || []);
  const language = useSelector((state) => state.app.language);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    dispatch(setLanguage(language));
    localStorage.setItem("language", language);
  };
  return (
    <div className={styles.wrapper}>
      {languageData.map(({ key = "", label = "" }) => (
        <LanguageBtn
          key={key}
          keyLang={key}
          label={label}
          lang={language}
          changeLanguage={changeLanguage}
        />
      ))}
    </div>
  );
};

const LanguageBtn = ({ keyLang, label, lang, changeLanguage }) => {
  return (
    <div
      className={`${styles.language} ${lang === keyLang && styles.active}`}
      onClick={() => changeLanguage(keyLang)}
    >
      {label}
    </div>
  );
};
