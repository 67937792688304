import React from "react";

//style
import styles from "./MainMap.module.scss";

const MainMap = () => {
  return (
    <section className={styles.section}>
      <div className={`${styles.map} container`}>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2542.431799521566!2d30.523669415730126!3d50.414426079470466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4cf3e9850332d%3A0x235defc99ea81b30!2z0YPQuy4g0JTQttC-0L3QsCDQnNCw0LrQutC10LnQvdCwLCAx0JAsINCa0LjQtdCyLCAwMjAwMA!5e0!3m2!1sru!2sua!4v1633119545401!5m2!1sru!2sua"
          width="100%"
          height="100%"
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
};
export default MainMap;
