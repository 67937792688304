import { getRequest } from "../../API/api";
import {
  clearLoadingAndError,
  setErrorMessage,
  setLoadingStatus,
} from "./appCreators";

import {
  SUCCESS_BLOG_POSTS,
  SUCCESS_BLOG_POSTS_META,
  SUCCESS_BLOG_POST,
} from "../actionsConsts.js";

export const successBlogPosts = (mas) => ({
  type: SUCCESS_BLOG_POSTS,
  mas,
});

export const successBlogPostsMeta = (obj) => ({
  type: SUCCESS_BLOG_POSTS_META,
  obj,
});

export const successBlogPost = (mas) => ({
  type: SUCCESS_BLOG_POST,
  mas,
});

export const getBlogPosts = (params, headers) => (dispatch) => {
  dispatch(setLoadingStatus());
  const success = (data) => {
    dispatch(successBlogPosts(data.data));
    dispatch(successBlogPostsMeta(data.meta));
    dispatch(clearLoadingAndError());
  };
  const setError = () => {
    dispatch(setErrorMessage());
  };
  getRequest({
    url: "/posts",
    success,
    setError,
    params,
    headers,
  });
};

export const getBlogPost = (id, headers) => (dispatch) => {
  dispatch(setLoadingStatus());
  const success = (data) => {
    dispatch(successBlogPost(data.data));
    dispatch(clearLoadingAndError());
  };
  const setError = () => {
    dispatch(setErrorMessage());
  };
  getRequest({
    url: `/posts/${id}`,
    success,
    setError,
    headers,
  });
};
