import React from "react";

//style
import styles from "./BlogArticleAdvantage.module.scss";

const BlogArticleAdvantage = ({ text }) => {
  return (
    <section className={`${styles.section} container`}>
      {text && text !== "<p>#$#</p>" && (
        <>
          <div
            className={styles.wrapperText}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></div>
        </>
      )}
    </section>
  );
};
export default BlogArticleAdvantage;
