import React from "react";
import { useTranslation } from "react-i18next";

const FormModalSuccess = () => {
  const { t } = useTranslation();

  return (
    <div className="form-modal__header form-modal__header--success">
      <h4 className="form-modal__title">{t("thankYou")}</h4>
      <p className="form-modal__subtitle">{t("successRequestDescription")}</p>
    </div>
  );
};

export default FormModalSuccess;
