import {
  SUCCESS_BLOG_POSTS,
  SUCCESS_BLOG_POSTS_META,
  SUCCESS_BLOG_POST,
} from "../actionsConsts";

const initialState = {
  blogPosts: [],
  blogPostsMeta: {},
  blogPost: [],
};

export const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_BLOG_POSTS: {
      return {
        ...state,
        blogPosts: action.mas,
      };
    }
    case SUCCESS_BLOG_POSTS_META: {
      return {
        ...state,
        blogPostsMeta: action.obj,
      };
    }
    case SUCCESS_BLOG_POST: {
      return {
        ...state,
        blogPost: action.mas,
      };
    }

    default:
      return state;
  }
};
