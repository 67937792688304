import { getRequest } from "../../API/api";
import {
  clearLoadingAndError,
  setErrorMessage,
  setLoadingStatus,
} from "./appCreators";

import { SUCCESS_MAIN_SPECIALIST } from "../actionsConsts.js";

export const successGetMainSpecialist = (obj) => ({
  type: SUCCESS_MAIN_SPECIALIST,
  obj,
});

export const getMainSpecialist = (headers) => (dispatch) => {
  dispatch(setLoadingStatus());
  const success = (data) => {
    dispatch(successGetMainSpecialist(data.data));
    dispatch(clearLoadingAndError());
  };
  const setError = () => {
    dispatch(successGetMainSpecialist(false));
    dispatch(setErrorMessage());
  };
  getRequest({
    url: "/specialists/42",
    success,
    setError,
    headers,
  });
};
