import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//style
import styles from "./Main.module.scss";

//components
import MainIntro from "./MainIntro/MainIntro";
import MainLazerSH from "./MainLazerSH/MainLazerSH";
import MainServices from "./MainServices/MainServices";
import MainLazerSH2 from "./MainLazerSH2/MainLazerSH2";
import MainBlog from "./MainBlog/MainBlog";
import MainMap from "./MainMap/MainMap";

import MetaComponent from "../modules/MetaComponent/MetaComponent";

//redux
import { getStoks } from "../../redux/actionCreators/stoksCreators";

const Main = () => {
  const { t } = useTranslation();

  const stoks = useSelector((state) => state.stoks.stoks);

  const language = useSelector((state) => state.app.language);

  const dispatch = useDispatch();
  useEffect(() => {
    const headers = {
      locale: language,
    };
    dispatch(getStoks(headers));
  }, []);

  const description = {
    uk: "У нашій клініці пацієнти не відчувають лікарняної атмосфери. Обстановка центрів сприяє відпочинку і налаштовує на позитив і чудовий результат звернення до наших лікарів.",
    ru: "В нашей клинике пациенты не чувствуют больничной атмосферы. Обстановка центров располагает к отдыху и настраивает на позитив и великолепный результат обращения к нашим докторам.",
  };

  const metaDescription = language === "ru" ? description.ru : description.uk;

  return (
    <>
      <MetaComponent title={"NEW DERM CLINIC"} description={metaDescription} />
      <main className={`${styles.main}`}>
        <MainIntro />
        <MainLazerSH stoks={stoks} />
        <MainServices />
        <MainLazerSH2 stoks={stoks} />
        <MainBlog />
        <MainMap />
      </main>
    </>
  );
};
export default Main;
