const fieldStateTemplate = {
    value: '',
    error: false,
    wasTouched: false,
    errorMessage: '',
}

const createFormState = formFields => {
    return formFields.reduce((resultState, field) => ({
        ...resultState,
        [field.name] : {...fieldStateTemplate}
    }), {});
};

export default createFormState;
