//modal
export const SET_MODAL_STATE = "SET_MODAL_STATE";

//media queries
export const SET_MEDIA_QUERIES = "SET_MEDIA_QUERIES";

//language
export const SET_LANGUAGE = "SET_LANGUAGE";

//app
export const SET_MODAL_WINDOW_STATUS = "SET_MODAL_WINDOW_STATUS";
export const SET_MODAL_WINDOW_CHILDREN_TYPE = "SET_MODAL_WINDOW_CHILDREN_TYPE";
export const SET_LOADING_STATUS = "SET_LOADING_STATUS";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const CLEAR_LOADING_AND_MESSAGE = "CLEAR_LOADING_AND_MESSAGE";
export const SET_TOP_OFFSET = "SET_TOP_OFFSET";

//SHARES
export const SUCCESS__SHARES = "SUCCESS__SHARES";

//blog
export const SUCCESS_BLOG_POSTS = "SUCCESS_BLOG_POSTS";
export const SUCCESS_BLOG_POSTS_META = "SUCCESS_BLOG_POSTS_META";
export const SUCCESS_BLOG_POST = "SUCCESS_BLOG_POST";

//categories
export const SUCCESS_ALL_CATEGRIES = "SUCCESS_ALL_CATEGRIES";
export const SUCCESS_CATEGORY = "SUCCESS_CATEGORY";
export const SUCCESS_POST_SERVICE = "SUCCESS_POST_SERVICE";
export const SUCCESS_PRICE_CATEGORY = "SUCCESS_PRICE_CATEGORY";
export const ALL_CATEGORIES_FRONT = "ALL_CATEGORIES_FRONT";
export const SUCCESS_SEARCH_POST_SERVICE = "SUCCESS_SEARCH_POST_SERVICE";

//stoks
export const SUCCESS_STOKS = "SUCCESS_STOKS";

//about
export const SUCCESS_MAIN_SPECIALIST = "SUCCESS_MAIN_SPECIALIST";
