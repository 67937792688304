import { SUCCESS_MAIN_SPECIALIST } from "../actionsConsts";

const initialState = {
  mainSpecialist: {},
};

const aboutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_MAIN_SPECIALIST: {
      return {
        ...state,
        mainSpecialist: action.obj,
      };
    }
    default:
      return state;
  }
};

export default aboutReducer;
