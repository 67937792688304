import {
  CLEAR_LOADING_AND_MESSAGE,
  SET_ERROR_MESSAGE,
  SET_LANGUAGE,
  SET_LOADING_STATUS,
  SET_MODAL_WINDOW_CHILDREN_TYPE,
  SET_MODAL_WINDOW_STATUS,
  SET_TOP_OFFSET,
} from "../actionsConsts.js";

export const setModalWindowStatus = (status) => ({
  type: SET_MODAL_WINDOW_STATUS,
  status,
});
export const setModalWindowChildrenType = (childrenType) => ({
  type: SET_MODAL_WINDOW_CHILDREN_TYPE,
  childrenType,
});

export const setLoadingStatus = () => ({ type: SET_LOADING_STATUS });
export const setErrorMessage = (error) => ({ type: SET_ERROR_MESSAGE, error });
export const clearLoadingAndError = () => ({ type: CLEAR_LOADING_AND_MESSAGE });
export const setLanguage = (language) => ({ type: SET_LANGUAGE, language });
export const setTopOffset = (offset) => ({ type: SET_TOP_OFFSET, offset });
