export const isValidPhoneFormat = (value) => {
  const regEx = new RegExp(/^\+?(380)\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/);

  if (!regEx.test(value)) {
    return {
      isValid: false,
      errorType: {
        ru: "неверно введенный номер, пример номера +380123456789",
        uk: "невірно введений  номер, приклад номеру +380123456789",
      },
    };
  } else {
    return {
      isValid: true,
    };
  }
};
