import {
  SUCCESS_ALL_CATEGRIES,
  SUCCESS_CATEGORY,
  SUCCESS_POST_SERVICE,
  SUCCESS_PRICE_CATEGORY,
  ALL_CATEGORIES_FRONT,
  SUCCESS_SEARCH_POST_SERVICE,
} from "../actionsConsts";

//utils
import { parseAllServiceSort } from "../../utils/parsers/parsers";

const initialState = {
  allService: [],
  category: {},
  postService: {},
  categoryPrices: {},
  allcategories: [],
  searchPostService: [],
};

export const categoriesServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_ALL_CATEGRIES: {
      return {
        ...state,
        allService: parseAllServiceSort(action.mas),
      };
    }
    case SUCCESS_CATEGORY: {
      return {
        ...state,
        category: action.obj,
      };
    }
    case ALL_CATEGORIES_FRONT: {
      return {
        ...state,
        allcategories: action.mas,
      };
    }
    case SUCCESS_SEARCH_POST_SERVICE: {
      return {
        ...state,
        searchPostService: action.mas,
      };
    }

    case SUCCESS_POST_SERVICE: {
      return {
        ...state,
        postService: action.obj,
      };
    }
    case SUCCESS_PRICE_CATEGORY: {
      return {
        ...state,
        categoryPrices: action.obj,
      };
    }

    default:
      return state;
  }
};
