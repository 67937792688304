export const IncludeMassElement = (checked, isChecked, setIsChecked) => {
  if (!isChecked.includes(checked)) {
    setIsChecked(() => [...isChecked, checked]);
  } else {
    let index = isChecked.findIndex((item) => item === checked);
    setIsChecked(() => [
      ...isChecked.slice(0, index),
      ...isChecked.slice(index + 1),
    ]);
  }
  return { isChecked };
};

export const IncludeMassElementModifive = (
  checked,
  isChecked,
  setIsChecked
) => {
  let isElement = false;
  isChecked.map((item) => {
    if (item?.name === checked) {
      isElement = true;
    }
    return item;
  });

  if (!isElement) {
    setIsChecked([
      ...isChecked,
      {
        name: checked,
      },
    ]);
  } else {
    let index = isChecked.findIndex((item) => item.name === checked);
    setIsChecked([...isChecked.slice(0, index), ...isChecked.slice(index + 1)]);
  }
};

export const IncludeMassElementInObj = (
  checked,
  isChecked,
  setIsChecked,
  element
) => {
  isChecked = isChecked.map((item) => {
    if (item?.name === checked) {
      if (item?.subMass) {
        if (!item?.subMass?.includes(element)) {
          item.subMass = [...item.subMass, element];
        } else {
          let index = item.subMass.findIndex((item) => item === element);
          item.subMass = [
            ...item.subMass.slice(0, index),
            ...item.subMass.slice(index + 1),
          ];
        }
      } else {
        item.subMass = [element];
      }
    }
    return item;
  });
  setIsChecked(isChecked);
};
