import React, { useEffect, useReducer, useState } from "react";

import { useTranslation } from "react-i18next";
import { signFormFields } from "../../../globalConsts";
import createFormState from "../../../utils/stateReducers/createFormState";
import formReducer from "../../../utils/stateReducers/formReducer";
import Form from "../Form/Form";
// import { getServices } from "../../../API/requests";
import { connect } from "react-redux";

const FormModalSign = (props) => {
  const [t] = useTranslation("common");
  const [signFormValues, signFormValuesDispatch] = useReducer(
    formReducer,
    signFormFields,
    createFormState
  );
  const [services, setServices] = useState([]);

  useEffect(() => {
    // getServices().then((response) => setServices(response.data.data));
  }, [props.language]);

  const onSignFormSubmit = () => {
    props.setModalState("success");
  };

  return (
    <>
      <div className="form-modal__header form-modal__header--sign">
        <h4 className="form-modal__title">{t("enroll")}</h4>
      </div>

      <Form
        className="form-modal__form"
        inputClassName="form-modal__input"
        formFields={signFormFields}
        formValues={signFormValues}
        formDispatch={signFormValuesDispatch}
        onFormSubmitSuccess={onSignFormSubmit}
        options={services}
      />
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    language: store.languageReducer.language,
  };
};

export default connect(mapStateToProps)(FormModalSign);
