import { SUCCESS_STOKS } from "../actionsConsts";

const initialState = {
  stoks: [],
};

const stoksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_STOKS: {
      return {
        ...state,
        stoks: action.mas,
      };
    }
    default:
      return state;
  }
};

export default stoksReducer;
