import { isNotEmpty, isValidPhoneFormat } from "./utils/formFieldValidators";

const languageRu = "ru";
const languageUk = "uk";

export const _language = languageRu;
export const _languages = [languageRu, languageUk];

export const callbackFormFields = [
  {
    title: "yourName",
    type: "text",
    name: "name",
    placeholder: "form.placeholderName",
    validators: [isNotEmpty],
  },
  {
    title: "yourPhoneNumber",
    type: "tel",
    name: "phone",
    placeholder: "form.placeholderPhone",
    validators: [isNotEmpty, isValidPhoneFormat],
  },
];

export const signFormFields = [
  {
    title: "chooseService",
    type: "dropdown",
    name: "service",
    validators: [isNotEmpty],
  },
  {
    title: "yourName",
    type: "text",
    name: "name",
    validators: [isNotEmpty],
  },
  {
    title: "yourPhoneNumber",
    type: "tel",
    name: "phone",
    validators: [isNotEmpty, isValidPhoneFormat],
  },
];
