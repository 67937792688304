import { combineReducers } from "redux";
import modalReducer from "./modalReducer";
import { sharesReducer } from "./sharesReducer";
import { appReducer } from "./appReducer";
import { blogReducer } from "./blogReducer";
import { categoriesServiceReducer } from "./categoriesServiceReducer";
import { headerReducer } from "./headerReducer";
import stoksReducer from "./stoksReducer";
import aboutReducer from "./aboutReducer";

const rootReducer = combineReducers({
  modalReducer,
  shares: sharesReducer,
  app: appReducer,
  blog: blogReducer,
  services: categoriesServiceReducer,
  header: headerReducer,
  stoks: stoksReducer,
  about: aboutReducer,
});

export default rootReducer;
