import React, { useReducer } from "react";

import { useTranslation } from "react-i18next";
import { callbackFormFields } from "../../../globalConsts";
import createFormState from "../../../utils/stateReducers/createFormState";
import Form from "../Form/Form";
import formReducer from "../../../utils/stateReducers/formReducer";

const FormModalCallback = ({ setModalState }) => {
  const { t } = useTranslation();
  const [callbackFormValues, callBackFormValuesDispatch] = useReducer(
    formReducer,
    callbackFormFields,
    createFormState
  );

  const onCallbackFormSubmit = () => {
    setModalState("success");
  };

  return (
    <>
      <div className="form-modal__header form-modal__header--callback">
        <h4 className="form-modal__title">{t("formModalCallback.title")}</h4>
        <p className="form-modal__subtitle">
          {t("formModalCallback.subtitle")}
        </p>
      </div>

      <Form
        className="form-modal__form"
        inputClassName="form-modal__input"
        formFields={callbackFormFields}
        formValues={callbackFormValues}
        formDispatch={callBackFormValuesDispatch}
        onFormSubmitSuccess={onCallbackFormSubmit}
      />
    </>
  );
};

export default FormModalCallback;
