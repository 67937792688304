import React from "react";
import { useDispatch, useSelector } from "react-redux";

//styles
import styles from "./Pagination.module.scss";

//images
import arrowRightIco from "../../../assets/icons/arrow-right-white.svg";

//utils
// import { createPages } from "../../../utils/pagesCreator";

export const Pagination = ({
  currentPage,
  lastPage,
  numberOfPages,
  pageSize,
  getData,
}) => {
  const dispatch = useDispatch();

  const language = useSelector((state) => state.app.language);

  let pages = [];
  // pages = createPages(pages, numberOfPages, currentPage);
  for (let index = 0; index < numberOfPages; index++) {
    pages.push({
      page: index + 1,
    });
  }

  return (
    <div className={styles.pagination}>
      {currentPage > 1 && (
        <div className={`${styles.arrow} ${styles.arrow_prev}`}>
          <img
            src={arrowRightIco}
            alt="arrow"
            onClick={() => {
              dispatch(
                getData({
                  lang: language,
                  page: currentPage - 1,
                  pageSize: pageSize,
                })
              );
            }}
          />
        </div>
      )}
      <div className={styles.numbers}>
        {pages.map((item, index) => (
          <div
            key={index}
            className={`${styles.number} ${
              index + 1 === currentPage && styles.active
            }`}
            onClick={() => {
              // window.scrollTo(0, 0);
              dispatch(
                getData({
                  lang: language,
                  page: index + 1,
                  pageSize: pageSize,
                })
              );
            }}
          >
            {index + 1}
          </div>
        ))}
      </div>
      {currentPage >= lastPage ? (
        ""
      ) : (
        <div className={`${styles.arrow} ${styles.arrow_next}`}>
          <img
            src={arrowRightIco}
            alt="arrow"
            onClick={() => {
              dispatch(
                getData({
                  lang: language,
                  page: currentPage + 1,
                  pageSize: pageSize,
                })
              );
            }}
          />
        </div>
      )}
    </div>
  );
};
