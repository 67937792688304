import React from "react";
import { useTranslation } from "react-i18next";

import ContactsForm from "./ContactsForm";
import "./Contacts.scss";

import MetaComponent from "../modules/MetaComponent/MetaComponent";

const Contacts = (props) => {
  const { t } = useTranslation();
  const position = {
    lat: 50.41544783100098,
    lng: 30.527771322687283,
  };

  return (
    <>
      <MetaComponent title={t("header.navigation.contacts")} />
      <main className="contacts-wrap">
        <section className="contacts">
          <ContactsForm
            setModalState={props.setModalState}
            position={position}
          />

          <div className="contacts-map">
            <iframe
              title="ddd"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2542.431799521566!2d30.523669415730126!3d50.414426079470466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4cf3e9850332d%3A0x235defc99ea81b30!2z0YPQuy4g0JTQttC-0L3QsCDQnNCw0LrQutC10LnQvdCwLCAx0JAsINCa0LjQtdCyLCAwMjAwMA!5e0!3m2!1sru!2sua!4v1636022927524!5m2!1sru!2sua"
              width="600"
              height="450"
              loading="lazy"
            />
          </div>
        </section>
      </main>
    </>
  );
};

export default Contacts;
