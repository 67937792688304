import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/configureStore";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./i18n";

//images
import loader from "./assets/icons/Loader.svg";

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div className="loader">
          <img
            style={{ width: "300px", height: "300px" }}
            src={loader}
            alt="loader"
          />
        </div>
      }
    >
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
