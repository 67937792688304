import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//style
import styles from "./Shares.module.scss";

//modules
import Loader from "../modules/Loader";
import MetaComponent from "../modules/MetaComponent/MetaComponent";

//redux
import { getShares } from "../../redux/actionCreators/sharesCreators";

//api
import { _image } from "../../API/API_consts";

const Shares = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const shares = useSelector((state) => state.shares.shares);

  const loading = useSelector((state) => state.app.loading);

  useEffect(() => {
    dispatch(getShares());
  }, []);

  return (
    <>
      <MetaComponent title={t("stock")} />
      <main className={`${styles.main}`}>
        <h1 className={styles.title}>{t("stock")}</h1>

        {!loading ? (
          <div className={`${styles.wrapperBloks} container`}>
            {shares.length > 0
              ? shares.map((item) => {
                  return (
                    <div key={item.id} className={styles.block}>
                      <img src={`${_image}/${item.photo}`} alt="img" />
                    </div>
                  );
                })
              : t("sorry")}
          </div>
        ) : (
          <Loader />
        )}
      </main>
    </>
  );
};
export default Shares;
