export const itemsUk = [
  "0",
  "Діагностика та видалення новоутворень",
  "Корекція рубців",
  "Кріотерапія (рідкий азот)",
  "Масажі",
  "Маски для обличчя",
  "ТОП-процедури",
  "Видалення пігментних плям, тату, татуаж (8)",
  "Видалення розширених судин",
  "Фотолазерне лікування",
];
export const itemsRu = [
  "0",
  "Диагностика и удаление новообразований",
  "Коррекция рубцов",
  "Криотерапия (жидкий азот)",
  "Массажи",
  "ТОП-процедуры",
  "Удаление пигментных пятен, тату, татуаж (8)",
  "Удаление расширенных сосудов",
  "Фотолазерное лечение",
  "Маски для лица",
];
