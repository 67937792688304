import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//modules
import Blog from "../../modules/BlogSection/BlogSection";

//redux
import { getBlogPosts } from "../../../redux/actionCreators/blogCreators";

const pageSize = 4;

const ServicePageBlog = () => {
  const dispatch = useDispatch();

  const language = useSelector((state) => state.app.language);

  const blogPosts = useSelector((state) => state.blog.blogPosts);

  useEffect(() => {
    const params = {
      page: 1,
      pageSize: pageSize,
    };
    const header = {
      locale: language,
    };
    dispatch(getBlogPosts(params, header));
  }, [language]);

  return <Blog blogPosts={blogPosts} />;
};
export default ServicePageBlog;
