import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//style
import styles from "./Blog.module.scss";

//modules
import BlogSection from "../modules/BlogSection/BlogSection";
import Pagination from "../modules/Pagination";
import Loader from "../modules/Loader";
import MetaComponent from "../modules/MetaComponent/MetaComponent";

//redux
import { getBlogPosts } from "../../redux/actionCreators/blogCreators";

const pageSize = 12;

const Blog = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.app.loading);

  const language = useSelector((state) => state.app.language);

  const blog = useSelector((state) => state.blog);

  const { blogPosts, blogPostsMeta } = blog;

  const numberOfPages = Math.ceil(blogPostsMeta?.total / pageSize);

  useEffect(() => {
    const params = {
      page: 1,
      pageSize: pageSize,
    };
    const headers = {
      locale: language,
    };
    dispatch(getBlogPosts(params, headers));
  }, [language]);

  return (
    <>
      <MetaComponent title={"Блог"} />
      <main className={styles.main}>
        <h1 className={`${styles.title} container`}>Блог</h1>
        {!loading ? (
          blogPosts.length > 0 ? (
            <BlogSection blogPosts={blogPosts} />
          ) : (
            t("sorry")
          )
        ) : (
          <Loader />
        )}
        {blogPosts && numberOfPages > 1 && (
          <Pagination
            numberOfPages={numberOfPages}
            currentPage={blogPostsMeta?.current_page}
            lastPage={blogPostsMeta?.last_page}
            pageSize={pageSize}
            getData={getBlogPosts}
            otherMethod
          />
        )}
      </main>
    </>
  );
};
export default Blog;
