import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ContactsForm = (props) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState(false);

  const redirectToMapDirection = () => {
    window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${props.position.lat},${props.position.lng}`;
  };

  return (
    <div className={`contacts-form-container ${formState ? "active" : ""}`}>
      <button
        className={`form-show-btn ${formState ? "active" : ""}`}
        onTouchStart={() => setFormState((prevState) => !prevState)}
      >
        {formState ? t("showMap") : t("showContacts")}
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.610793 0.00244045C0.490764 0.00221634 0.393291 0.0993633 0.393067 0.219392C0.392965 0.277257 0.415948 0.332778 0.456922 0.373651L5.08479 5.0011L0.456922 9.62852C0.371938 9.7135 0.371938 9.85128 0.456922 9.93626C0.541905 10.0212 0.67968 10.0212 0.764664 9.93626L5.54598 5.15495C5.63082 5.07008 5.63082 4.93251 5.54598 4.84763L0.764664 0.0663357C0.723914 0.0254431 0.668535 0.00244045 0.610793 0.00244045Z"
            fill="white"
          />
        </svg>
      </button>

      <div
        className="contacts-form"
        itemScope="itemscope"
        itemType="https://schema.org/Organization"
      >
        <div className="contacts-form__body">
          <h3 className="contacts-form__title">
            <div className="contacts-form__line" />
            {t("header.navigation.contacts")}
          </h3>

          <div
            className="contacts-form__address-wrap"
            itemProp="address"
            itemScope="itemscope"
            itemType="https://schema.org/PostalAddress"
          >
            <svg
              width="22"
              height="32"
              viewBox="0 0 22 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9997 0C5.1182 0 0.333008 4.78519 0.333008 10.6667C0.333008 12.4323 0.774445 14.1829 1.61363 15.7357L10.4164 31.6562C10.5336 31.8685 10.7569 32 10.9997 32C11.2425 32 11.4658 31.8685 11.583 31.6562L20.389 15.7304C21.2249 14.1829 21.6664 12.4323 21.6664 10.6666C21.6664 4.78519 16.8812 0 10.9997 0ZM10.9997 16C8.05894 16 5.66638 13.6074 5.66638 10.6667C5.66638 7.72594 8.05894 5.33337 10.9997 5.33337C13.9404 5.33337 16.333 7.72594 16.333 10.6667C16.333 13.6074 13.9404 16 10.9997 16Z"
                fill="white"
              />
            </svg>

            <a
              href="https://goo.gl/maps/1RzY3TukpYb9T93z7"
              target="_blank"
              rel="noreferrer"
              className="contacts-form__address"
              itemProp="streetAddress"
            >
              {t("footer.street")}
            </a>
          </div>

          <button
            className="contacts-form__get-directions"
            onClick={redirectToMapDirection}
          >
            {t("contacts.getDirections")}
          </button>

          <div className="work-schedule">
            {t("footer.schedule")}
            <p className="work-schedule__item">пн-пт. 10.00 - 19.00</p>
            <p className="work-schedule__item">cб. 11.00 - 18.00</p>
          </div>

          <div className="contacts-form__call-section">
            <a href="tel:+380674421696" itemProp="telephone">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.4011 16.1458L18.3309 13.0756C17.2344 11.9791 15.3704 12.4178 14.9318 13.8432C14.6028 14.83 13.5064 15.3783 12.5195 15.1589C10.3265 14.6107 7.36602 11.7598 6.81778 9.45721C6.48883 8.47033 7.14673 7.37384 8.13357 7.04493C9.559 6.60634 9.9976 4.74231 8.90111 3.64582L5.83094 0.575656C4.95375 -0.191885 3.63797 -0.191885 2.87043 0.575656L0.787099 2.65898C-1.29623 4.85196 1.0064 10.6633 6.15989 15.8168C11.3134 20.9703 17.1248 23.3826 19.3177 21.1896L21.4011 19.1063C22.1687 18.2291 22.1687 16.9133 21.4011 16.1458Z"
                  fill="white"
                />
              </svg>
              +38 067 442 1696
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsForm;
