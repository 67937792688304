import React from "react";

//style
import styles from "./ServicePageIntro.module.scss";

//api
import { _image } from "../../../API/API_consts";

const ServicePageIntro = ({ name, subtitle, image }) => {
  return (
    <>
      <section className={`${styles.section} `}>
        <div className={`${styles.wrapperText}`}>
          <h1 className={styles.h1}>{name}</h1>
          {subtitle && (
            <div dangerouslySetInnerHTML={{ __html: subtitle }}></div>
          )}
        </div>
        <img src={`${_image}/${image}`} alt="img" className={styles.img} />
      </section>
    </>
  );
};
export default ServicePageIntro;
