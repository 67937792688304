import axios from "axios";

import { _api } from "./API_consts";

export const combineURLs = (_api, relativeURL) => {
  return relativeURL
    ? _api.replace(/\/+$/, "") + "/" + relativeURL.replace(/^\/+/, "")
    : _api;
};

export const getRequest = ({ url, success, setError, params, headers }) => {
  axios
    .get(`${combineURLs(_api, url)}`, {
      headers,
      params,
    })
    .then((res) => success(res.data))
    .catch((err) => {
      setError(err);
    });
};
