import React from "react";

//style
import styles from "./ServicePageAdvantage.module.scss";

const ServicePageAdvantage = ({ description }) => {
  return (
    <section className={`${styles.section} container`}>
      {description && description !== "<p>#$#</p>" && (
        <>
          <div
            className={styles.wrapperText}
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </>
      )}
    </section>
  );
};
export default ServicePageAdvantage;
