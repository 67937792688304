import { languageData } from "../../DB/headerData";

const initialState = {
  language: languageData,
};

export const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
