import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

//style
import styles from "./HeaderTop.module.scss";

//images
import logo from "../../../assets/images/footer/Logo.png";
import instagram from "../../../assets/images/footer/instagram.png";
import facebook from "../../../assets/images/footer/facebook.png";

//redux
import { setModalState } from "../../../redux/actionCreators/modalActionCreators";
import { useDispatch } from "react-redux";

//modules
import SwitchLanguage from "../../modules/SwitchLanguage";
const HeaderTop = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <div className={styles.section}>
      <div className={`${styles.inner} container`}>
        <Link to="/" className={styles.logo}>
          <img src={logo} alt="logo" />
        </Link>
        <SwitchLanguage />
        <div className={styles.wrapperTell}>
          <div className={styles.icons}>
            <a
              href="https://www.facebook.com/NEW.DERM.CLINIC.Kyiv"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/new.derm.clinic/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="instagram" />
            </a>
          </div>
          <a
            href="tel:+380674421696"
            itemProp="telephone"
            className={styles.tell}
          >
            +38 067 442 1696
          </a>
          <button
            type="button"
            className={styles.button}
            onClick={() => dispatch(setModalState("callback"))}
          >
            {t("callback")}
          </button>
        </div>
      </div>
    </div>
  );
};
export default HeaderTop;
