import {SET_MODAL_STATE} from "../actionsConsts";

const initialState = {
    modalState: 'closed',
};

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MODAL_STATE: {
            return {
                ...state,
                modalState: action.payload,
            };
        }
        default: return state
    }
};

export default modalReducer;
