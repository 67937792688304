import {
  CLEAR_LOADING_AND_MESSAGE,
  SET_ERROR_MESSAGE,
  SET_LANGUAGE,
  SET_LOADING_STATUS,
  SET_MODAL_WINDOW_CHILDREN_TYPE,
  SET_MODAL_WINDOW_STATUS,
  SET_TOP_OFFSET,
} from "../actionsConsts.js";

const initialState = {
  userName: "Станіслав Білібін",
  modalWindowStatus: false,
  modalWindowChildrenType: "",
  loading: false,
  error: null,
  language: localStorage.getItem("language") || "ru",
  topOffset: 0,
  zoomModalObject: null,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_STATUS:
      return {
        ...state,
        loading: true,
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CLEAR_LOADING_AND_MESSAGE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SET_MODAL_WINDOW_STATUS:
      return {
        ...state,
        modalWindowStatus: action.status,
      };
    case SET_MODAL_WINDOW_CHILDREN_TYPE:
      return {
        ...state,
        modalWindowChildrenType: action.childrenType,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case SET_TOP_OFFSET:
      return {
        ...state,
        topOffset: action.offset,
      };
    default:
      return state;
  }
};
