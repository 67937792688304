import React from "react";

//style
import styles from "./BlogArticleIntro.module.scss";

//api
import { _image } from "../../../API/API_consts";

const BlogArticleIntro = ({ title, subtitle, image }) => {
  return (
    <>
      <section className={`${styles.section} `}>
        <div className={`${styles.wrapperText}`}>
          <h1 className={styles.h1}>{title}</h1>
          {subtitle && subtitle !== "<p>#$#</p>" && (
            <>
              <div
                className={styles.wrapperSubtitle}
                dangerouslySetInnerHTML={{
                  __html: subtitle,
                }}
              />
            </>
          )}
        </div>
        <img src={`${_image}/${image}`} alt="img" className={styles.img} />
      </section>
    </>
  );
};
export default BlogArticleIntro;
