import { getRequest } from "../../API/api";
import {
  clearLoadingAndError,
  setErrorMessage,
  setLoadingStatus,
} from "./appCreators";

import { SUCCESS__SHARES } from "../actionsConsts.js";

export const successShares = (mas) => ({
  type: SUCCESS__SHARES,
  mas,
});

export const getShares = (params, headers) => (dispatch) => {
  dispatch(setLoadingStatus());
  const success = (data) => {
    dispatch(successShares(data.data));
    dispatch(clearLoadingAndError());
  };
  const setError = () => {
    dispatch(setErrorMessage());
  };
  getRequest({
    url: "/certificates",
    success,
    setError,
    params,
    headers,
  });
};
