import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//style
import styles from "./Prices.module.scss";

//modules
import ServiceAndPrice from "../modules/ServiceAndPrice/ServiceAndPrice";
import Loader from "../modules/Loader";
import MetaComponent from "../modules/MetaComponent/MetaComponent";

//redux
import { getAllCategories } from "../../redux/actionCreators/categoriesServiceCreators";

const Prices = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.app.loading);

  const language = useSelector((state) => state.app.language);

  const services = useSelector((state) => state.services);

  const { allService } = services;

  useEffect(() => {
    const headers = {
      locale: language,
    };
    dispatch(getAllCategories(headers));
  }, [language]);

  return (
    <>
      <MetaComponent title={t("header.navigation.price")} />
      <main className={`${styles.main}`}>
        <h1 className={styles.title}>{t("header.navigation.price")}</h1>
        {!loading ? (
          allService ? (
            <ServiceAndPrice allService={allService} />
          ) : (
            t("sorry")
          )
        ) : (
          <Loader />
        )}
      </main>
    </>
  );
};
export default Prices;
