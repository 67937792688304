import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import arrow from "../../../assets/images/general/input-arrow.svg";
import { _api } from "../../../API/API_consts";
import axios from "axios";
import { useSelector } from "react-redux";

const Form = (props) => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.app.language);

  const form = t("form", { returnObjects: true });

  // const backCall = () => {
  //   axios.post(`${_api}/send-appointment-request`, {
  //     service_id: "" && props.formValues.service.value,
  //     name: props.formValues.name.value,
  //     phone: props.formValues.phone.value,
  //   });
  // };

  const setTouchStateOfField = (name) => {
    props.formDispatch({ type: "set_touched", payload: { name } });
  };

  const validateFormField = (name, value, validators) => {
    let isFieldValid = true;

    if (validators) {
      isFieldValid = !validators.some((validator) => {
        const validationResult = validator(value);

        if (validationResult.isValid) {
          props.formDispatch({ type: "reset_error", payload: { name } });

          return false;
        } else {
          const errorMessage = validationResult.errorType;
          console.log(validationResult);
          props.formDispatch({
            type: "set_error",
            payload: { name, errorMessage },
          });

          return true;
        }
      });
    }

    return isFieldValid;
  };

  const handleFormFieldsChange = (event, validators) => {
    const { name, value } = event.target;

    props.formDispatch({ type: "set_value", payload: { name, value } });

    validateFormField(name, value, validators);
  };

  const handleFieldBlur = (event, validators) => {
    const { name, value } = event.target;

    validateFormField(name, value, validators);
    setTouchStateOfField(name);
  };

  const setDropdownOption = (option, name, validators) => {
    props.formDispatch({ type: "set_value", payload: { name, value: option } });

    validateFormField(name, option, validators);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    let isFormValid = true;

    props.formFields.forEach((field) => {
      if (
        !validateFormField(
          field.name,
          props.formValues[field.name].value,
          field.validators
        )
      ) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      props.onFormSubmitSuccess();
      axios.post(`${_api}/send-appointment-request`, {
        service_id: "1",
        name: props.formValues.name.value,
        phone: props.formValues.phone.value,
      });
    }
  };

  const [dropdownState, setDropdownState] = useState(false);

  return (
    <form className={props.className} onSubmit={handleFormSubmit}>
      {props.formFields.map((field) =>
        field.type === "dropdown" ? (
          <div
            key={field.name}
            className={`dropdown-input ${dropdownState ? "active" : ""}`}
          >
            <input
              className={classNames(
                { invalid: props.formValues[field.name].error },
                {
                  valid:
                    props.formValues[field.name].wasTouched &&
                    !props.formValues[field.name].error,
                }
              )}
              type="text"
              placeholder={form[field.placeholder]}
              value={props.formValues[field.name].value}
              onClick={() => setDropdownState((prevState) => !prevState)}
              onBlur={(event) => handleFieldBlur(event, field.validators)}
              readOnly
            />

            <span
              className={classNames("input-error-message", {
                active: props.formValues[field.name].error,
              })}
            >
              {props.formValues[field.name].errorMessage}
            </span>

            <img
              className="dropdown-input__arrow"
              src={arrow}
              alt="arrow"
              onClick={() => setDropdownState((prevState) => !prevState)}
            />

            <div className="dropdown-input__body">
              {props.options.map((option) => (
                <div
                  key={option.id}
                  className="dropdown-input__item"
                  onClick={() => {
                    setDropdownOption(
                      option.name,
                      field.name,
                      field.validators
                    );
                    setDropdownState(false);
                  }}
                >
                  {option.name}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <label key={field.name} className="form-label">
            <input
              className={classNames(
                props.inputClassName,
                { invalid: props.formValues[field.name].error },
                {
                  valid:
                    props.formValues[field.name].wasTouched &&
                    !props.formValues[field.name].error,
                }
              )}
              type={field.type}
              name={field.name}
              placeholder={t(field.placeholder)}
              value={props.formValues[field.name].value}
              onChange={(event) =>
                handleFormFieldsChange(event, field.validators)
              }
              onBlur={(event) => handleFieldBlur(event, field.validators)}
            />

            <span
              className={classNames("input-error-message", {
                active: props.formValues[field.name].error,
              })}
            >
              {props.formValues[field.name].error &&
                props.formValues[field.name].errorMessage[0][language]}
            </span>
          </label>
        )
      )}

      <button
        className="btn-accent-form"
        type="submit"
        // onClick={() => backCall()}
      >
        Отправить
      </button>
    </form>
  );
};

export default Form;
