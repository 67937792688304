import React from "react";
import { MetaTags } from "react-meta-tags";

//icons
// import { logoSvg } from "../../../assets/icons";

const MetaComponent = ({ title = "", description }) => {
  return (
    <>
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content={description} />
      </MetaTags>
    </>
  );
};

export default MetaComponent;
