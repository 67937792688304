export const isNotEmpty = (value) => {
  if (!value.trim().length) {
    return {
      isValid: false,
      errorType: {
        ru: "в данном поле пусто, введите что-нибудь",
        uk: "в даному полі порожньо, введіть щось",
      },
    };
  } else {
    return {
      isValid: true,
    };
  }
};
