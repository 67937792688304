import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//style
import styles from "./Services.module.scss";

//components
import ServicesSelect from "./ServicesSelect/ServicesSelect";
import ServicesSH from "./ServicesSH/ServicesSH";
import ServicePrices from "./ServicePrices/ServicePrices";
import ServicesBlog from "./ServicesBlog/ServicesBlog";

//modules
import Loader from "../modules/Loader";
import MetaComponent from "../modules/MetaComponent/MetaComponent";

//redux
import { getAllCategories } from "../../redux/actionCreators/categoriesServiceCreators";
import { getStoks } from "../../redux/actionCreators/stoksCreators";

const Services = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const language = useSelector((state) => state.app.language);

  const allService = useSelector((state) => state.services.allService);

  const stoks = useSelector((state) => state.stoks.stoks);

  useEffect(() => {
    const headers = {
      locale: language,
    };
    dispatch(getAllCategories(headers));
  }, [language]);

  useEffect(() => {
    const headers = {
      locale: language,
    };
    if (stoks.length < 1) {
      dispatch(getStoks(headers));
    }
  }, []);

  return (
    <>
      <MetaComponent title={t("header.navigation.services")} />
      <main className={`${styles.main}`}>
        <h1 className={styles.title}>{t("header.navigation.services")}</h1>

        <ServicesSelect allService={allService} />
        <ServicesSH stoks={stoks} />
        <ServicePrices allService={allService} />
        <ServicesBlog />
      </main>
    </>
  );
};
export default Services;
