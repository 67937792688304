import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//style
import styles from "./About.module.scss";

//modules
import Loader from "../modules/Loader";
import MetaComponent from "../modules/MetaComponent/MetaComponent";
//redux
import { getMainSpecialist } from "../../redux/actionCreators/aboutCreators";

//untils
import { parseSpecialistEntity } from "../../utils/parsers/parsers";

const About = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  let mainSpecialist = useSelector((state) => state.about.mainSpecialist);

  const loading = useSelector((state) => state.app.loading);

  const language = useSelector((state) => state.app.language);

  mainSpecialist = parseSpecialistEntity(mainSpecialist);

  useEffect(() => {
    const headers = {
      locale: language,
    };
    dispatch(getMainSpecialist(headers));
  }, [language]);

  return (
    <>
      <MetaComponent title={t("header.navigation.about")} />
      <main className={`${styles.main}`}>
        <h1 className={styles.title}>{t("header.navigation.about")}</h1>
        {!loading ? (
          mainSpecialist ? (
            <div className={`${styles.wrraper} container`}>
              <div className={styles.wrapperImg}>
                <img
                  src={mainSpecialist?.photo}
                  alt="img"
                  className={styles.img}
                />
                <p>
                  {mainSpecialist?.position} {mainSpecialist?.name}
                </p>
              </div>
              <div
                className={styles.wrapperText}
                dangerouslySetInnerHTML={{
                  __html: mainSpecialist?.description,
                }}
              ></div>
            </div>
          ) : (
            <div className={`${styles.wrraper} container`}>{t("sorry")}</div>
          )
        ) : (
          <Loader />
        )}
      </main>
    </>
  );
};
export default About;
