import React from "react";
import { Route, Switch } from "react-router-dom";

//components
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Main from "./components/Main/Main";
import Blog from "./components/Blog/Blog";
import Services from "./components/Services/Services";
import ServicePage from "./components/ServicePage/ServicePage";
import BlogArticle from "./components/BlogArticle/BlogArticle";
import Contacts from "./components/Contacts";
import Prices from "./components/Prices/Prices";
import Shares from "./components/Shares/Shares";
import About from "./components/About/About";

//modal
import FormModal from "./components/modules/FormModal";

const App = () => {
  return (
    <>
      <FormModal />
      <Header />
      <Switch>
        <Route path="/" exact component={Main} />
        <Route path="/services" exact component={Services} />
        <Route path="/services/:servicePage" exact component={ServicePage} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/blog/:blogArticle" exact component={BlogArticle} />
        <Route path="/prices" exact component={Prices} />
        <Route path="/shares" exact component={Shares} />
        <Route path="/about" exact component={About} />
        <Route path="/contacts" exact component={Contacts} />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
