import axios from "axios";
//redux
import { allCategoriesFront } from "../redux/actionCreators/categoriesServiceCreators";

const itemsUk = [
  "0",
  "Діагностика та видалення новоутворень",
  "Корекція рубців",
  "Кріотерапія (рідкий азот)",
  "Масажі",
  "Маски для обличчя",
];
const itemsRu = [
  "0",
  "Диагностика и удаление новообразований",
  "Коррекция рубцов",
  "Криотерапия (жидкий азот)",
  "Массажи",
  "Маски для лица",
];
export const getAllcategoriesNotNeedServices = (allcategories) => {
  if (allcategories.length > 0) {
    const allcategoriesCopy = allcategories?.map((item) => {
      item.services = item.services.filter((service) => {
        return (
          !itemsUk.includes(service.name) && !itemsRu.includes(service.name)
        );
      });
      return item;
    });
    return allcategoriesCopy;
  }
};

export const getAllcategoriesServices = (
  dispatch,
  headers,
  allService,
  allcategories
) => {
  let bufercategories = [];
  let counterEntry = 0;
  // if (allService && allcategories?.length < 1) {
  allService?.map((item) => {
    axios
      .get(`https://adminkandc.sysale.agency/api/categories/${item.id}`, {
        headers,
      })
      .then((res) => Allcategories(res.data.data, dispatch))
      .catch((err) => {
        console.log(err);
      });
  });
  // }
  const Allcategories = (data, dispatch) => {
    counterEntry++;
    if (bufercategories.length < 1) {
      bufercategories.push(data);
    } else {
      let isMass = false;
      bufercategories.map((item) => {
        if (item.id === data.id) {
          isMass = true;
        }
        return item;
      });
      if (!isMass) {
        bufercategories.push(data);
      }
    }
    if (allService.length === counterEntry) {
      dispatch(allCategoriesFront(bufercategories));
    }
  };
};
