import { SUCCESS__SHARES } from "../actionsConsts";

const initialState = {
  shares: [],
};

export const sharesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS__SHARES: {
      return {
        ...state,
        shares: action.mas,
      };
    }

    default:
      return state;
  }
};
