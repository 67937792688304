import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//styles
import styles from "./MainBlog.module.scss";

//modules
import BlogSection from "../../modules/BlogSection/BlogSection";

//redux
import { getBlogPosts } from "../../../redux/actionCreators/blogCreators";

const pageSize = 4;

const MainBlog = () => {
  const dispatch = useDispatch();

  const language = useSelector((state) => state.app.language);

  const blogPosts = useSelector((state) => state.blog.blogPosts);

  useEffect(() => {
    const params = {
      page: 1,
      pageSize: pageSize,
    };
    const headers = {
      locale: language,
    };
    dispatch(getBlogPosts(params, headers));
  }, [language]);

  return (
    <>
      <h2 className={styles.title}>Блог</h2>
      <BlogSection blogPosts={blogPosts} />
    </>
  );
};
export default MainBlog;
