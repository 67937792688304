const formReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case 'set_touched': return {
            ...state,
            [payload.name]: {
                ...state[payload.name],
                wasTouched: true,
            },
        };
        case 'reset_error': return {
            ...state,
            [payload.name]: {
                ...state[payload.name],
                error: false,
            },
        };
        case 'set_error': return  {
            ...state,
            [payload.name]: {
                ...state[payload.name],
                error: true,
                errorMessage: [payload.errorMessage],
            },
        };
        case 'set_value': return  {
            ...state,
            [payload.name]: {
                ...state[payload.name],
                value: payload.value,
            },
        };
    }
};

export default formReducer;
