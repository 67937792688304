import React from "react";
import { NavLink } from "react-router-dom";

//style
import styles from "./Blog.module.scss";

//api
import { _image } from "../../../API/API_consts";

const BlogSection = ({ blogPosts }) => {
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.groupBlocks}>
          {blogPosts?.map(({ id, title, image }) => {
            return (
              <NavLink to={`/blog/${id}`} className={styles.block} key={id}>
                <div className={styles.wrapperIcon}>
                  <img src={`${_image}/${image}`} alt="image" />{" "}
                </div>
                <span className={styles.text}>{title}</span>
              </NavLink>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default BlogSection;
