import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

//style
import styles from "./ServicePage.module.scss";

//components
import ServicePageSelect from "./ServicePageSelect/ServicePageSelect";
import ServicePageIntro from "./ServicePageIntro/ServicePageIntro";
import ServicePageBlog from "./ServicePageBlog/ServicePageBlog";
import ServicePageAdvantage from "./ServicePageAdvantage/ServicePageAdvantage";

//modules
import Loader from "../modules/Loader";
import MetaComponent from "../modules/MetaComponent/MetaComponent";

//redux
import {
  getPostService,
  getAllCategories,
} from "../../redux/actionCreators/categoriesServiceCreators";

//utils
import { parseHTMLtextToTExt } from "../../utils/parsers/parsers";

const ServicePage = () => {
  const local = useParams();

  const dispatch = useDispatch();

  const services = useSelector((state) => state.services);

  const loading = useSelector((state) => state.app.loading);

  const language = useSelector((state) => state.app.language);

  const { allService, postService } = services;
  useEffect(() => {
    window.scrollTo(0, 0);
    const headers = {
      locale: language,
    };

    dispatch(getPostService(local.servicePage, headers));
    dispatch(getAllCategories(headers));
  }, [language]);

  const description = parseHTMLtextToTExt(postService?.subtitle);

  return (
    <>
      <MetaComponent title={postService?.name} description={description} />
      <main className={`${styles.main}`}>
        {!loading ? (
          postService ? (
            <>
              <ServicePageIntro {...postService} />
              <div className={styles.innerItem}>
                <ServicePageAdvantage {...postService} />
              </div>
            </>
          ) : (
            "Вибачте"
          )
        ) : (
          <Loader />
        )}

        <ServicePageSelect allService={allService} />
        <ServicePageBlog />
      </main>
    </>
  );
};
export default ServicePage;
