import { getRequest } from "../../API/api";
import {
  clearLoadingAndError,
  setErrorMessage,
  setLoadingStatus,
} from "./appCreators";

import {
  SUCCESS_ALL_CATEGRIES,
  SUCCESS_CATEGORY,
  SUCCESS_POST_SERVICE,
  SUCCESS_PRICE_CATEGORY,
  ALL_CATEGORIES_FRONT,
  SUCCESS_SEARCH_POST_SERVICE,
} from "../actionsConsts.js";

export const successAllCategories = (mas) => ({
  type: SUCCESS_ALL_CATEGRIES,
  mas,
});

export const successCategory = (obj) => ({
  type: SUCCESS_CATEGORY,
  obj,
});
export const successPostService = (obj) => ({
  type: SUCCESS_POST_SERVICE,
  obj,
});

export const successPriceCategory = (obj) => ({
  type: SUCCESS_PRICE_CATEGORY,
  obj,
});

export const allCategoriesFront = (mas) => ({
  type: ALL_CATEGORIES_FRONT,
  mas,
});

export const successSearchPostServices = (mas) => ({
  type: SUCCESS_SEARCH_POST_SERVICE,
  mas,
});

export const getAllCategories = (headers) => (dispatch) => {
  dispatch(setLoadingStatus());
  const success = (data) => {
    dispatch(successAllCategories(data.data));
    dispatch(clearLoadingAndError());
  };
  const setError = () => {
    dispatch(setErrorMessage());
  };
  getRequest({
    url: "/categories",
    success,
    setError,
    headers,
  });
};

export const getPricesCategory = (id, headers) => (dispatch) => {
  const success = (data) => {
    dispatch(successPriceCategory(data.data));
  };
  const setError = () => {
    dispatch(setErrorMessage());
  };
  getRequest({
    url: `/categories/${id}`,
    success,
    setError,
    headers,
  });
};

export const getCategory = (id, headers) => (dispatch) => {
  dispatch(setLoadingStatus());
  const success = (data) => {
    dispatch(successCategory(data.data));
    dispatch(clearLoadingAndError());
  };
  const setError = () => {
    dispatch(setErrorMessage());
  };
  getRequest({
    url: `/categories/${id}`,
    success,
    setError,
    headers,
  });
};

export const getPostService = (id, headers) => (dispatch) => {
  dispatch(setLoadingStatus());
  const success = (data) => {
    dispatch(successPostService(data.data));
    dispatch(clearLoadingAndError());
  };
  const setError = () => {
    dispatch(setErrorMessage());
  };
  getRequest({
    url: `/services/${id}`,
    success,
    setError,
    headers,
  });
};

export const getSearchPostServices = (params, headers) => (dispatch) => {
  const success = (data) => {
    dispatch(successSearchPostServices(data.data));
    dispatch(clearLoadingAndError());
  };
  const setError = () => {
    dispatch(setErrorMessage());
  };
  getRequest({
    url: `/services/search`,
    success,
    setError,
    headers,
    params,
  });
};
