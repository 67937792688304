import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

//style
import styles from "./HeaderNav.module.scss";

const HeaderNav = () => {
  const { t } = useTranslation();

  const [mobileBurger, setMobileBurger] = useState(false);

  const isIpadMini = useMediaQuery({ maxWidth: 768 });

  const showMenu = () => {
    setMobileBurger(!mobileBurger);
  };

  const navigation = [
    {
      id: 1,
      link: "/services",
      name: t("header.navigation.services"),
    },
    {
      id: 2,
      link: "/shares",
      name: t("header.navigation.shares"),
    },
    {
      id: 3,
      link: "/blog",
      name: t("header.navigation.blog"),
    },
    {
      id: 4,
      link: "/prices",
      name: t("header.navigation.price"),
    },
    {
      id: 5,
      link: "/about",
      name: t("header.navigation.about"),
    },
    {
      id: 6,
      link: "/contacts",
      name: t("header.navigation.contacts"),
    },
  ];

  return (
    <>
      <nav className={styles.nav}>
        {isIpadMini && (
          <div className={styles.wrapperMobileToggle}>
            <div
              className={`${styles.mobileToggle} ${
                mobileBurger ? styles.mobileToggleActive : ""
              }`}
              onClick={showMenu}
            >
              <span />
              <span />
            </div>
          </div>
        )}
        {(mobileBurger || !isIpadMini) && (
          <ul
            className={`${styles.list} ${
              mobileBurger ? styles.listActive : styles.listNotActive
            } container`}
          >
            {navigation.map(({ id, link, name }) => {
              return (
                <li key={id} onClick={() => setMobileBurger(false)}>
                  <Link to={link} className={styles.link}>
                    {name}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </nav>
    </>
  );
};
export default HeaderNav;
