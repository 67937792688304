import React, { useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useSelector } from "react-redux";

//style
import styles from "./ServicesSelect.module.scss";

//module
import {
  IncludeMassElementModifive,
  IncludeMassElementInObj,
} from "../../modules/IncludeMassElement";
//modules
import Loader from "../../modules/Loader";

//api
import { _image } from "../../../API/API_consts";

//utils
import { itemsUk, itemsRu } from "../../../DB/deleteNotNeedwords";

const ServicesSelect = ({ allService }) => {
  const [showMore, setShowMore] = useState([]);

  const loading = useSelector((state) => state.app.loading);

  const handleonClickShow = (element) => {
    IncludeMassElementModifive(element, showMore, setShowMore);
  };
  const handleonClickShowSub = (element, elementParent) => {
    IncludeMassElementInObj(elementParent, showMore, setShowMore, element);
  };

  const services = useSelector((state) => state.services);

  let { allcategories } = services;

  return (
    <section className={styles.section}>
      <nav className={styles.nav}>
        <ul className={`${styles.list} container`}>
          {!loading ? (
            <>
              {allService &&
                allService?.map((item, index) => {
                  return (
                    <li key={`${index}${item?.id}`}>
                      <div
                        className={styles.wrapperLink}
                        onClick={() => {
                          handleonClickShow(item.id);
                        }}
                      >
                        <span className={styles.link}>{item.name}</span>
                        <MdOutlineArrowBackIosNew
                          className={
                            showMore.find((el) => el.name === item.id)
                              ? styles.iconActive
                              : styles.icon
                          }
                        />
                      </div>
                      {allcategories?.map((category, index) => (
                        <ul
                          className={
                            category?.id === item?.id &&
                            showMore.find((el) => el.name === category?.id)
                              ? styles.subListActive
                              : styles.subList
                          }
                          key={`${index}${category?.id}`}
                        >
                          {category?.services?.map(
                            (
                              { id, name, image, subtitle, description },
                              index
                            ) => {
                              return (
                                <>
                                  {!itemsUk.includes(name) &&
                                    !itemsRu.includes(name) && (
                                      <li
                                        className={styles.subLink}
                                        key={`${index}${id}`}
                                      >
                                        <div
                                          onClick={() => {
                                            handleonClickShowSub(
                                              id,
                                              category?.id
                                            );
                                          }}
                                        >
                                          <span className={styles.subLinkTitle}>
                                            {name}
                                          </span>
                                          <MdOutlineArrowBackIosNew
                                            className={
                                              showMore.find((el) =>
                                                el?.subMass?.includes(id)
                                              )
                                                ? styles.iconActive
                                                : styles.icon
                                            }
                                          />
                                        </div>

                                        <div
                                          className={
                                            showMore.find((el) =>
                                              el?.subMass?.includes(id)
                                            )
                                              ? styles.desctiptionActive
                                              : styles.desctiption
                                          }
                                        >
                                          <img
                                            src={`${_image}/${image}`}
                                            alt="img"
                                            className={styles.img}
                                          />
                                          {subtitle &&
                                            subtitle !== "<p>#$#</p>" && (
                                              <>
                                                <div
                                                  className={
                                                    styles.wrapperSubtitle
                                                  }
                                                  dangerouslySetInnerHTML={{
                                                    __html: subtitle,
                                                  }}
                                                ></div>
                                              </>
                                            )}

                                          {description &&
                                            description !== "<p>#$#</p>" && (
                                              <>
                                                <div
                                                  className={
                                                    styles.wrapperDescription
                                                  }
                                                  dangerouslySetInnerHTML={{
                                                    __html: description,
                                                  }}
                                                ></div>
                                              </>
                                            )}
                                        </div>
                                      </li>
                                    )}
                                </>
                              );
                            }
                          )}
                        </ul>
                      ))}
                    </li>
                  );
                })}
            </>
          ) : (
            <Loader />
          )}
        </ul>
      </nav>
    </section>
  );
};

export default ServicesSelect;
