import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

//style
import styles from "./ServiceAndPrice.module.scss";

//redux
import { getPricesCategory } from "../../../redux/actionCreators/categoriesServiceCreators";

//module
import { IncludeMassElement } from "../../modules/IncludeMassElement";

//utils
import { getAllcategoriesServices } from "../../../utils/getAllcategoriesServices";
const ServicePageSelect = ({ allService }) => {
  const dispatch = useDispatch();

  const [showMore, setShowMore] = useState([]);

  const handleonClickShow = (element) => {
    IncludeMassElement(element, showMore, setShowMore);
  };

  const services = useSelector((state) => state.services);

  const language = useSelector((state) => state.app.language);

  const { allcategories } = services;

  const headers = {
    locale: language,
  };

  useEffect(() => {
    getAllcategoriesServices(dispatch, headers, allService, allcategories);
  }, [allService]);

  return (
    <section className={styles.section}>
      <nav className={styles.nav}>
        <ul className={`${styles.list} container`}>
          {allService?.map(({ name, id }) => {
            return (
              <li key={id}>
                <div
                  className={styles.wrapperLink}
                  onClick={() => {
                    handleonClickShow(id);
                  }}
                >
                  <span className={styles.link}>Прайс {name}</span>
                  <MdOutlineArrowBackIosNew
                    className={
                      showMore.includes(id) ? styles.iconActive : styles.icon
                    }
                  />
                </div>
                {allcategories?.map((category, index) => (
                  <div
                    className={
                      category.id === id && showMore.includes(category.id)
                        ? styles.subListActive
                        : styles.subList
                    }
                    key={`${index}${category?.id}`}
                  >
                    {category?.services?.map((item, index) => {
                      return (
                        <>
                          {item.sub_services.length > 0 && item.name !== "0" && (
                            <div
                              className={styles.nameService}
                              key={`${index}${item?.id}`}
                            >
                              {" "}
                              {item.name}{" "}
                            </div>
                          )}
                          {item.sub_services.map((item, index) => {
                            return (
                              <div
                                className={styles.row}
                                key={`${index}${item?.id}`}
                              >
                                <span className={styles.service}>
                                  {item.name}
                                </span>
                                <span className={styles.price}>
                                  {item.final_price} грн
                                </span>
                              </div>
                            );
                          })}
                        </>
                      );
                    })}
                  </div>
                ))}
              </li>
            );
          })}
        </ul>
      </nav>
    </section>
  );
};

export default ServicePageSelect;
