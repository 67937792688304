import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//style
import styles from "./BlogArticle.module.scss";

//components
// import BlogArticleSelect from "./BlogArticleSelect/BlogArticleSelect";
import BlogArticleIntro from "./BlogArticleIntro/BlogArticleIntro";
import BlogArticleBlog from "./BlogArticleBlog/BlogArticleBlog";
import BlogArticleAdvantage from "./BlogArticleAdvantage/BlogArticleAdvantage";

import Loader from "../modules/Loader";
import MetaComponent from "../modules/MetaComponent/MetaComponent";

//utils
import { parseHTMLtextToTExt } from "../../utils/parsers/parsers";

//redux
import { getBlogPost } from "../../redux/actionCreators/blogCreators";

const BlogArticle = () => {
  const { t } = useTranslation();

  const local = useParams();

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.app.loading);

  const language = useSelector((state) => state.app.language);

  const blog = useSelector((state) => state.blog);

  const { blogPost } = blog;

  useEffect(() => {
    window.scrollTo(0, 0);
    const headers = {
      locale: language,
    };
    dispatch(getBlogPost(local.blogArticle, headers));
  }, [local.blogArticle, language]);

  const description = parseHTMLtextToTExt(blogPost?.subtitle);

  return (
    <>
      <MetaComponent title={blogPost?.title} description={description} />

      <main className={`${styles.main}`}>
        <div className={styles.wrapperTitle}></div>
        {!loading ? (
          blogPost ? (
            <>
              <BlogArticleIntro {...blogPost} />
              <div className={styles.innerItem}>
                <BlogArticleAdvantage {...blogPost} />
              </div>
            </>
          ) : (
            t("sorry")
          )
        ) : (
          <Loader />
        )}
        <BlogArticleBlog />
      </main>
    </>
  );
};
export default BlogArticle;
