import { _image } from "../../API/API_consts";

import i18n from "i18next";

const parseImage = (image) =>
  image.includes("http") ? image : `${_image}/${image}`;

export const parseSpecialistEntity = (specialist) => {
  return {
    ...specialist,
    photo: specialist?.photo ? parseImage(specialist?.photo) : "",
  };
};

export const parseAllServiceSort = (allService) => {
  let buferItem;
  let allcategoriesCopy;
  if (allService) {
    allService.map((item, index) => {
      if (item?.name === i18n.t("dermatooncology")) {
        buferItem = item;
        allcategoriesCopy = [
          ...allService.slice(0, index),
          ...allService.slice(index + 1),
        ];
      }
    });
    allcategoriesCopy?.unshift(buferItem);
  }
  return allcategoriesCopy;
};

export const parseHTMLtextToTExt = (text) => {
  let result = null;
  if (text) {
    const regex = /(&nbsp;|<([^>]+)>)/gi;
    result = text.replace(regex, "").replace("&ndash;", "-").substr(0, 145);
  }
  return result;
};

// export const parsePostEntity = (post) => {
//   return {
//     ...post,
//     image: parseImage(post.image),
//   };
// };

// export const parseServiceEntity = (service) => {
//   return {
//     ...service,
//     category: service.category || {},
//     icon: service.icon ? parseImage(service.icon) : "",
//     image: service.image ? parseImage(service.image) : "",
//   };
// };

// export const parseCertificateEntity = (certificate) => {
//   return {
//     ...certificate,
//     photo: certificate.photo ? parseImage(certificate.photo) : "",
//   };
// };

// export const parsePostEntities = (posts) =>
//   posts.map((post) => parsePostEntity(post));

// export const parseServiceEntities = (services) =>
//   services.map((service) => parseServiceEntity(service));
// export const parseSpecialistEntities = (specialists) =>
//   specialists.map((specialist) => parseSpecialistEntity(specialist));
// export const parseCertificateEntities = (certificates) =>
//   certificates.map((certificate) => parseCertificateEntity(certificate));
